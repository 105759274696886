
html,body{
  min-height:100%;
  min-width: 100%;
  
  font-family: "Montserrat", sans-serif;
  background-color: #F7F7F7;

  }
#root {
  margin: 0px;
  height: 100vh;
  min-width: 100%;

 
  }





.flex-tags {
  /* We first create a flex layout context */
  display: flex;

  /* Then we define the flow direction 
     and if we allow the items to wrap 
   * Remember this is the same as:
   * flex-direction: row;
   * flex-wrap: wrap;
   */
  flex-flow: row wrap;

  /* Then we define how is distributed the remaining space */
  justify-content:center;
}
.tagsBlock{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  margin: 10px;
  padding: 10px;
  transition: 0.5s;
  cursor: pointer;
}
.tagsBlockselected{
  background:#edb323
}
