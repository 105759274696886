.empCard{
    width: 260px;
    height: 380px;
    gap: 0px;
    border: 1px solid #B2AFAF;
    background: #fff;
    border-radius: 22px;
    border-color: #ECE9EE;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start ;
    transition: 0.5s;
    
  }
  .empCard .imgBox{

  
  }

  /* Line 159 */

  .ruler{
    border: 0.5px solid #B2AFAF;
    width: 300px;
    margin-left:30px;


  }
  .empCard .imgBox img{

  }
  
  
  .empCard:hover {
    transform: scale(1.03);
  }


  
.empDetail{
    padding-top: 25px;
    align-items: center;
    text-align: center ;
  
  }
  .empName{
    font-size: 25px;
    font-weight: 700; 
    font-family: "Montserrat", sans-serif;
    color: #1E1B4A;
  }
  .emptitle{
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.92px;
    text-align: center;
    color: #707070;


  }