@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  background: #dee1e2;
  min-height: 100vh;
  overflow-x: hidden;
}



