.banner{
    margin-top:0px;
    position: relative;
    top: 0;
    left: 0;
    z-index: 20;
    background-image: url("../../images/b305c1b5179c7e81b3b1653f31ddbbcc.png");
    background-repeat: no-repeat;
    background-size: cover;
    transition: height 300ms;
  }
  .layer {
    background-color: rgba(0, 0, 1, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

}

.bannerTitle{
  text-align: center;
    font-size: 72px;
    font-weight: 700;
    width: 100%;
     color: #fff;
   }
   .bannerSubTitle{
    text-align: center;
    width: 100%;
    font-size: 20px;
    font-weight: 400;
     color: #fff;
   }   

   .textbox{
    margin-top: 300px;
    height: 30vh;
    width: 70vw;
    display: flex;
    flex-direction: column;

   }
   .seperator{
    
    height: 5px;
    align-self: center;
    width: 211px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fff;

   }